<template>
  <!-- 挂牌管理 -->
  <div class="page-card-demo">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      reset
      :form-item-arr="searchFormItemArr"
    >
      <el-form-item label="商品类别" label-width="80px">
        <el-cascader
          ref="goodsCategoryRef"
          v-model="formInline.categoryArr"
          placeholder="请选择"
          :options="categoryOption"
          :props="categoryCascadeProps"
          clearable
          @change="categoryChange"
        />
      </el-form-item>
    </FormSearch>
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <template #goodsName="{ row }">
          <span>{{ row.categoryName }}/{{ row.varietyName }}/{{ row.goodsName }}</span>
        </template>
        <template #centralizedPurchaseTotalQuantity="{ row }">
          <span>{{ row.centralizedPurchaseTotalQuantity }}{{ $codeNew(meterageUnitOptions, row.meterageUnit) }}</span>
        </template>
        <template #projectEnterQuantity="{ row }">
          <span>{{ row.projectEnterQuantity }}{{ $codeNew(meterageUnitOptions, row.meterageUnit) }}</span>
        </template>
        <template #projectCompleteOrderTotalQuantity="{ row }">
          <span>{{ row.projectCompleteOrderTotalQuantity }}{{ $codeNew(meterageUnitOptions, row.meterageUnit) }}</span>
        </template>
        <template #projectStatus="{ row }">
          <span v-if="collectionStatus.has(row.projectStatus)" class="status" :style="{background: collectionStatus.get(row.projectStatus)[0],color: collectionStatus.get(row.projectStatus)[1]}">{{ $codeNew(projectStatus, row.projectStatus + '') }}</span>
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination v-if="listData.length" :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
  </div>
</template>
<script>
import FormSearch from '@/components/FormSearch.vue'
import Table from '@/components/Table.vue'
import Pagination from '@/components/Pagination2.vue'
import { getCollectionList } from '@/api/supplyChain'
import { goodsGetGoodsCategoryList } from '@/api/shoppingMall'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      categoryOption: [],
      // 划级联渲染规则
      categoryCascadeProps: {
        value: 'code',
        label: 'goodsName'
      },
      formInline: {
        // categoryArr: [],
        pageSize: 10,
        pageNum: 1,
        publishTimeOrderBy: 0,
        enterEndDateOrderBy: 0
      },
      listData: [],
      total: 0,
      loading: false,
      pdfContractConfig: {
        visible: false,
        url: ''
      },
      showBotton: false,
      meterageUnitOptions: this.$store.getters.getDictionaryItem('purchaseUnit'),
      collectionStatus: new Map([[1, ['#F7F7F7', '#999999']], [2, ['#EFF7FF', '#0172EB']], [3, ['#FFEDDC', '#E96114']], [4, ['#EFF7FF', '#0172EB']], [5, ['#E9FEF4', '#0CA41A']], [6, ['#F7F7F7', '#999999']]]),
      projectStatus: this.$store.getters.getDictionaryItem('COLLECTION_PROJECT_STATE'),
      searchFormItemArr: [
        { type: 'input', label: '集采项目编号', value: 'projectNo', width: '100px' },
        { type: 'input', label: '集采项目名称', value: 'projectName', width: '100px' },
        { type: 'input', label: '组织单位', value: 'publishCmpName', width: '100px' },

        // { type: 'select', label: '商品类别', value: 'appStat', pLabel: 'dictName', pValue: 'dictId', child: [], width: '80px' },
        { type: 'select', label: '集采项目状态', value: 'projectStatus', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('COLLECTION_PROJECT_STATE'), width: '100px' }
      ],
      itemData: [
        { label: '集采项目编号', prop: 'projectNo', width: 200 },
        { label: '组织单位', prop: 'publishCmpName', width: 200 },
        { label: '集采项目名称', prop: 'projectName', width: 180 },
        { label: '商品类别', prop: 'goodsName', width: 220 },
        { label: '预计集采总量', prop: 'centralizedPurchaseTotalQuantity', width: 180 },
        { label: '报名认购数量', prop: 'projectEnterQuantity', type: 'money', width: 180 },
        { label: '实际集采总量', prop: 'projectCompleteOrderTotalQuantity', width: 140 },
        { label: '集采单价(元)', prop: 'centralizedPurchasePrice', width: 140 },
        // { label: '待签署合同数', prop: 'waitSignPurchaseCount', width: 160 },
        { label: '尾款截止日', prop: 'balancePaymentEndDate', width: 140 },
        { label: '集采终止日', prop: 'projectEndDate', width: 140 },
        { label: '创建时间', prop: 'createTime', width: 160 },
        { label: '集采项目状态', prop: 'projectStatus', width: 120, child: this.$store.getters.getDictionaryItem('COLLECTION_PROJECT_STATE') }
      ],
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.detailCollection }
      ]
    }
  },
  created() {
    // 获取产品类别
    this.getCategoryList()
  },
  methods: {
    getCategoryList() {
      goodsGetGoodsCategoryList(res => {
        this.categoryOption = [...res.data]
        this.changeCategoryList(this.categoryOption)
      })
    },
    // 递归删除空子级
    changeCategoryList(data) {
      data.map((item) => {
        this.changeCategoryList(item.children)
        if (item.children.length === 0) {
          delete item.children
        }
      })
    },
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1,
          publishTimeOrderBy: 0,
          enterEndDateOrderBy: 0
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      getCollectionList(this.formInline, res => {
        this.listData = res.data.records.map(item => {
          item.balancePaymentEndDate = `${item.balancePaymentEndDate} 23:59`
          item.projectEndDate = `${item.projectEndDate} 23:59`
          item.createTime = item.createTime && item.createTime.slice(0, -3)
          return item
        })
        this.total = res.data.total
      })
    },
    // 级联change事件
    categoryChange(val) {
      console.log(val)
      if (val && val.length) {
        // const goodsArr = this.$refs['goodsCategoryRef'].getCheckedNodes()[0].pathNodes
        console.log(this.$refs['goodsCategoryRef'].getCheckedNodes()[0])
        // console.log(goodsCategoryRef.value.getCheckedNodes()[0])
        // this.formInline.goodsOneCategoryName = goodsArr[1]
        // this.formInline.goodsTwoCategoryName = goodsArr[2]
        this.formInline.categoryId = val[0]
        this.formInline.varietyId = val[1]
        this.formInline.goodsId = val[2]
      } else {
        this.formInline.categoryId = ''
        this.formInline.varietyId = ''
        this.formInline.goodsId = ''
      }
      console.log(val, this.formInline)
    },
    detailCollection({ projectId }) {
      this.$router.push({
        path: '/collectionProject/detail',
        query: {
          projectId
        }
      })
    }
  }

}
</script>
<style lang="scss" scoped>
.status {
  padding: 4px;
  border-radius: 4px;
}
</style>
